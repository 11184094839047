'use client';

import { type Node } from '@react-types/shared';
import classNames from 'classnames';
import { type ReactElement, useRef } from 'react';
import {
  type AriaTabListProps,
  type AriaTabPanelProps,
  type AriaTabProps,
  useFocusVisible,
  useTab,
  useTabList,
  useTabPanel,
} from 'react-aria';
import Skeleton from 'react-loading-skeleton';
import { Item, type TabListState, useTabListState } from 'react-stately';

import { Button } from '@shared/ui-components';
import { GTM_EVENT_NAME_INTERNAL_LINK_CLICK, sendGtmEvent, useMounted } from '@shared/utils';

import styles from './profile-dashboard-chart-section.module.scss';

type TabData = {
  chartSlot: ReactElement;
  tabSubTitle: string | null;
  tabTitle: string;
  tabType: string;
};

type CmsContent = {
  buttonLabel: string;
  buttonTarget: string;
  buttonUrl: string;
  sectionAriaLabel: string;
};

type TabItemProps = AriaTabProps & TabData;
type ProfileDashboardChartSectionProps = {
  cmsContent: CmsContent;
  hideTabs: boolean;
  tabsData: TabItemProps[];
};

const Tab = ({ item, state }: { item: Node<TabItemProps>; state: TabListState<TabItemProps> }) => {
  const isMounted = useMounted();
  const { isFocusVisible } = useFocusVisible();

  const { key, value } = item;
  const ref = useRef(null);
  const { isSelected, tabProps } = useTab({ key }, state, ref);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    tabProps.onClick?.(event);
    sendGtmEvent({
      eventName: GTM_EVENT_NAME_INTERNAL_LINK_CLICK,
      link_label: value?.tabType,
    });
  };

  return (
    <button
      className={classNames(styles['chart-section__tab'], {
        [styles['chart-section__tab--is-selected']]: isSelected,
        [styles['chart-section__tab--focus-visible']]: isMounted && isFocusVisible,
      })}
      key={item.key}
      {...tabProps}
      onClick={handleOnClick}
      ref={ref}
    >
      {value?.tabType}
    </button>
  );
};

const TabPanel = ({
  state,
  ...props
}: AriaTabPanelProps & { state: TabListState<TabItemProps> }) => {
  const ref = useRef(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);

  return (
    <div {...tabPanelProps} data-testid="chart-tab-content" ref={ref}>
      {state.selectedItem?.props?.children}
    </div>
  );
};

const Tabs = (props: AriaTabListProps<TabItemProps> & { hideTabs: boolean }) => {
  const state = useTabListState(props);
  const ref = useRef(null);
  const { tabListProps } = useTabList(props, state, ref);
  const { tabPanelProps } = useTabPanel(props, state, ref);
  const { hideTabs } = props;

  return (
    <>
      <div className={styles['chart-section__heading']} {...tabPanelProps}>
        <h2 aria-hidden="true" data-testid="chart-section-headline">
          {state.selectedItem ? (
            state.selectedItem?.value?.tabTitle
          ) : (
            <Skeleton className={styles['chart-section__headline-skeleton']} />
          )}
        </h2>
        {state.selectedItem ? (
          <div
            {...tabListProps}
            className={classNames(styles['chart-section__tabs-container'], {
              [styles['chart-section__tabs-container--hide']]: hideTabs,
            })}
            ref={ref}
          >
            {[...state.collection].map((item) => (
              <Tab item={item} key={item.key} state={state} />
            ))}
          </div>
        ) : (
          <Skeleton className={styles['chart-section__tabs-container-skeleton']} />
        )}
      </div>
      {state.selectedItem ? (
        <TabPanel key={state.selectedItem?.key} state={state} />
      ) : (
        <div>
          <div className={styles['chart-section__sub-heading-skeleton-container']}>
            <Skeleton className={styles['chart-section__sub-heading-skeleton']} count={1.7} />
          </div>
          <Skeleton className={styles['chart-section__chart-skeleton']} />
        </div>
      )}
    </>
  );
};

export const ProfileDashboardChartSection = ({
  cmsContent,
  hideTabs,
  tabsData,
}: ProfileDashboardChartSectionProps) => {
  return (
    <section className={styles['chart-section']} data-testid="chart-section">
      <Tabs aria-label={cmsContent.sectionAriaLabel} hideTabs={hideTabs} items={tabsData}>
        {(itemData) => (
          <Item>
            <h2 className={styles['chart-section__headline--visually-hidden']}>
              {itemData.tabTitle}
            </h2>
            {itemData.tabSubTitle && (
              <h3
                className={classNames(styles['chart-section__sub-heading'], {
                  [styles['chart-section__sub-heading--no-tabs']]: hideTabs,
                })}
              >
                {itemData.tabSubTitle}
              </h3>
            )}
            <div
              className={classNames(styles['chart-section__chart-slot'], {
                [styles['chart-section__chart-slot--hide']]: hideTabs,
              })}
            >
              {itemData.chartSlot}
            </div>
          </Item>
        )}
      </Tabs>
      <div className={styles['chart-section__button-container']}>
        <Button isExternal target={cmsContent.buttonTarget} to={cmsContent.buttonUrl}>
          {cmsContent.buttonLabel ? cmsContent.buttonLabel : <Skeleton />}
        </Button>
      </div>
    </section>
  );
};
